import React, { Component } from 'react'
import styled from 'styled-components'

import { Sub } from '../Typography'

const Section = styled.section`
  padding: 3rem;
  max-width: 700px;
  width: 100%;
  position: relative;
  z-index: 30;
  text-align: center;

  button {
    outline: none;
    transition: 0.2s ease-out transform;

    &:active {
      transform: scale(0.95, 0.95);
    }
  }

  @media (max-width: 750px) {
    width: 100%;
    max-width: 40rem;
    padding: 3rem 0;
  }
`

const Body = styled.div`
  padding: 1.5em;
  text-align: left;
  font-family: var(--font);
  line-height: 1.3;
  letter-spacing: 0.02em;
  font-size: 0.96rem;
  color: var(--lilac);
  ul {
     padding-inline-start: 40px !important;
  }
  a {
     text-decoration: underline;
  }
`

const SubModified = styled(Sub)`
  padding: 1rem;
`

export default class Terms extends Component {
   render() {
      return (

         <Section>
            <SubModified>Horizon Blockchain Games Inc. Terms of Use</SubModified>
            <SubModified>Version 1.3</SubModified>
            <SubModified>Last updated April 27th, 2022</SubModified>
            <Body>
               <p>
                  Please review these Terms of Use (<b>“Terms”</b>) carefully, as they
                  set forth the legally binding terms and conditions that govern your
                  use of our websites located at{' '}
                  <u>
                     <a
                        href="https://www.horizon.io"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        https://www.horizon.io
                     </a>
                  </u>
                  ,{' '}
                  <u>
                     <a
                        href="https://www.skyweaver.net"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        https://www.skyweaver.net
                     </a>
                  </u>

                  , (<b>“Websites”</b>) and our gaming platform (the{' '}
                  <b>“Platform”</b>), including related trademarks, software
                  code, and other intellectual property (collectively, the{' '}
                  <b>“Services”</b>). By accessing or using the Services, you
                  accept these Terms on behalf of yourself and any entity that you
                  represent, and you represent and warrant that you have the right and
                  authority to do so. You further represent and warrant that are fully
                  able and competent to enter into, and abide by and comply with, the
                  Sequence User Terms. If you do not agree with all of the provisions
                  of these Terms, do not access, or use the Services.
               </p>
               <br />
               <p>
                  By accessing or using the Services, you represent that you are of
                  the legal age of majority in your jurisdiction as may be required to
                  access and use the Sequence Services. If you have not reached the
                  age of majority in your jurisdiction but are at least thirteen (13)
                  years of age, your legal guardian must review these Sequence Terms
                  and explain them to you before you use the Sequence Services. The
                  Websites are a copyrighted work belonging to Horizon Blockchain
                  Games Inc. (<b>“Horizon,” “Company,” “us,” “our,” and “we”</b>
                  ). Your submission of information, including personal information,
                  through or in connection with the Services is governed by the terms
                  of our privacy policy as updated from time to time, available at{' '}
                  <a
                     href="https://horizon.io/privacy"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     https://horizon.io/privacy
                  </a>
                  {' '}(<b>“Privacy Policy”</b>). In order to play Skyweaver or
                  access or use the Services you will be required to first accept the
                  Privacy Policy. You will be prompted to accept the Privacy Policy
                  upon your initial registration of an Account by clicking to accept.
                  You will be prompted to accept updates to the Privacy Policy from
                  time to time when you log in to your Account by clicking to accept
                  the updated Privacy Policy.
               </p>
               <br />
               <p>
                  When you accept the Privacy Policy, and any updates thereto, you
                  consent to us collecting, accessing, using, processing, disclosing,
                  and retaining any personal information you provide to us so we may
                  provide the Services to you. This consent is not related to, and
                  does not affect, any rights or obligations we or you have in
                  accordance with data protection laws, privacy laws, and regulations.
               </p>
               <br />
               <p>
                  <b>
                     Some jurisdictions (which may include Quebec, Canada) do not allow
                     mandatory arbitration, prohibitions against class actions or
                     governing law and forums other than where the individual consumer
                     is located. If you are located in one of these jurisdictions, the
                     following sentence may not apply to you and you may have
                     additional rights. These terms require the use of arbitration
                     (Section 13) on an individual basis to resolve disputes, rather
                     than jury trials or class actions, and also limit the remedies
                     available to you in the event of a dispute.
                  </b>
               </p>
               <br />
               <SubModified id="platform">1. The Platform</SubModified>
               <p>
                  Users of the Platform will be able to access online gaming services
                  and interactive games produced by Horizon (<b>“Games”</b>) and
                  including the features available within each Game. To access Games,
                  users of the Platform must sign up for an account (
                  <b>“Account”</b>). Users with an Account (
                  <b>“Account Holder”</b>) will need to provide a username and
                  valid email to Horizon. Within each Game, Account Holders may earn
                  virtual game-specific items or abilities represented by certain
                  ERC-1155 blockchain tokens (<b>“Gaming Tokens”</b>) which are
                  wholly owned, possessed, and controlled by the Account Holder.
                  Account Holders may also purchase Gaming Tokens via a third party
                  marketplace accessible within each Game.
               </p>
               <br />
               <SubModified>1.1 Limited License Agreement</SubModified>
               <p>
                  Horizon owns, has licensed, or otherwise has rights to use all of
                  the content that appears in the Platform and on our Websites,
                  including original artwork that appears within the Games (
                  <b>“Artwork”</b>
                  ). Gaming Tokens do not grant any ownership rights over any Artwork
                  or any of Horizon's other intellectual property. Each user
                  acknowledges the rights attached to each Gaming Token do not exceed
                  the grant of rights provided by these Terms.
               </p><br />
               <p>
                  Notwithstanding any provision to the contrary herein, you agree that
                  you have no right to or title in any content that appears in the
                  Platform, including without limitation the Artwork, or any other
                  attributes associated with an Account.
               </p><br />
               <p>
                  Subject to these Terms, Horizon grants you a non-transferable,
                  non-exclusive, revocable, limited license to use and access the
                  Platform for your own personal and non-commercial use. Horizon
                  reserves all rights, including without limitation, all intellectual
                  property rights or other proprietary rights, in connection with the
                  Games, the Platform and the Websites. Users may display, copy or
                  make use of any of the Artwork for commercial use only with the
                  prior written consent of Horizon. For the avoidance of doubt, Users
                  can play Skyweaver outside of the Platform using their Gaming Tokens
                  in digital or printed format.
               </p>
               <br />
               <SubModified>1.2 Platform Restrictions</SubModified>
               <p>
                  The following restrictions apply to the use of the Platform:
               </p><br />
               <p>
                  <ul>
                     <li>
                        You represent and warrant that you are legally permitted to use
                        the Services in your jurisdiction including owning Blockchain
                        Assets and interacting with the Services in any way. You further
                        represent you are responsible for ensuring compliance with the
                        laws of your jurisdiction and acknowledge that Horizon is not
                        liable for your compliance or non-compliance with such laws.
                     </li><br />
                     <li>
                        You represent and warrant that you are not a person or entity
                        identified on: (a) a list established under section 83.05 of the
                        Criminal Code (Canada), any Regulations made under the United
                        Nations Act (Canada), the Freezing Assets of Corrupt Foreign
                        Officials Act (Canada), the Justice for Victims of Corrupt
                        Foreign Officials Act (Canada), the Special Economic Measures
                        Act (Canada) or any other Canadian statutes or regulations that
                        take legislative measures against terrorist financing and
                        against financial dealings with certain sanctioned individuals
                        and entities; (b) a list of specially designated national and/or
                        blocked persons maintained by the Office of Foreign Assets
                        Control of the United States Treasury Department; (c) a list of
                        denied persons or parties of concern maintained by the Bureau of
                        Industry and Security of the United States Department of
                        Commerce; or (d) any similar list promulgated by an official
                        agency, ministry or department of the United States.
                     </li><br />
                     <li>
                        You represent and warrant that you are not a resident of, or
                        located in, any of the countries listed here{' '}
                        <a
                           href="https://support.skyweaver.net/en/articles/6105623-skyweaver-list-of-restricted-regions"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Restricted Regions
                        </a>
                        {' '}to which Canada or the United States has deemed a Prohibited Jurisdiction.
                     </li><br />
                     <li>
                        You shall not access your Account or the Platform using a
                        virtual private network or any similar tool that would allow you
                        to circumvent any restriction that in this Section that would
                        otherwise apply to your use of your Account or the Platform.
                     </li><br />
                     <li>
                        You shall not engage in any automated use of the Platform or
                        automated gameplay, including through the use of any code,
                        software, scripts, robots, or similar automatic devices that are
                        not expressly permitted by Horizon pursuant to these Terms.
                     </li><br />
                     <li>
                        You shall not (or attempt to) purchase, sell, rent, or give away
                        your Account, or create an Account using a false identity or
                        information, or on behalf of someone other than yourself.
                     </li><br />
                     <li>
                        You shall not use the Platform if your account has previously
                        been removed by Horizon, or you have previously been banned from
                        playing any Horizon game.
                     </li><br />
                     <li>
                        You shall use your Account only for non-commercial purposes.
                     </li><br />
                     <li>
                        You shall not use the Platform to advertise, solicit, or
                        transmit any commercial advertisements, including spam or
                        repetitive or misleading messages to anyone.
                     </li><br />
                     <li>
                        You shall not post any information that is abusive, threatening,
                        obscene, defamatory, libelous, or racially, sexually,
                        religiously, or otherwise objectionable or offensive or engage
                        in ongoing toxic behavior, such as by repeatedly posting
                        information on an unsolicited basis.
                     </li><br />
                     <li>
                        You shall not disrupt, interfere with, or otherwise adversely
                        affect the normal flow of the Platform or otherwise act in a
                        manner that may negatively affect other users' experience when
                        using the Platform. This includes any kind of manipulation of
                        rankings or taking advantage of errors to gain an unfair edge
                        over other users and any other act that intentionally abuses or
                        goes against the design of the Platform.
                     </li><br />
                     <li>
                        You agree not to probe, scan, or test the vulnerability of the
                        Websites or Skyweaver, or breach the security or authentication
                        measures of the Websites or any part of the Services, except in
                        connection with, and in accordance with the rules of, a Horizon
                        sponsored bounty program.
                     </li>
                  </ul><br />
               </p>
               <p>
                  Horizon reserves the right to determine, except where prohibited by
                  applicable law, what conduct it considers to be in violation of
                  these restrictions. Horizon reserves the right to take action as a
                  result, which may include terminating your Account and prohibiting
                  you from accessing the Platform in whole or in part.
               </p>
               <br />
               <SubModified>1.3 Account Deletion</SubModified>
               <p>
                  Horizon may delete your Account in accordance with Section 7 if we
                  deem, except where prohibited by applicable law, in our sole
                  discretion, your username or avatar to be offensive or
                  inappropriate. You may permanently delete your Account on the
                  Platform by clicking on “Profile Settings” in the Platform and
                  choosing the option to delete. Deletion of your Account does not
                  have any effect on your ownership of Blockchain Assets (defined
                  below), which includes Gaming Tokens.
               </p>
               <br />
               <SubModified>1.4 Use of Chat Rooms</SubModified>
               <p>
                  Horizon shall have the right to monitor and/or record your
                  communications when you use the Platform, and you acknowledge and
                  agree that when you use the Platform, you have no expectation that
                  your communications will be private. Horizon shall have the right to
                  disclose your communications for any reason, including: (a) to
                  satisfy any applicable law, regulation, legal process or
                  governmental request; (b) to enforce these Terms or Privacy Policy;
                  (c) to protect Horizon's legal rights and remedies; (d) to protect
                  the health or safety of anyone that Horizon believes may be
                  threatened; or (e) to report a crime or other offensive behavior.
               </p>
               <br />
               <SubModified>1.5 User Interactions</SubModified>
               <p>
                  You are solely responsible for your interactions with other users of
                  the Platform and any other parties with whom you interact through
                  the Platform. Horizon reserves the right, but has no obligation, to
                  become involved in any way with these disputes. You will fully
                  cooperate with Horizon to investigate any suspected unlawful,
                  fraudulent or improper activity.
               </p>
               <br />
               <SubModified>
                  1.6 Gaming Tokens, Fees and Billing
               </SubModified>
               <p>
                  Gaming Tokens, fees, and billing procedures may change over time.
                  The existence of a particular offer for Gaming Tokens does not mean
                  Horizon will maintain or continue to make available that particular
                  Gaming Token or that particular offer. The scope, variety, and type
                  of Gaming Tokens that you may obtain can change at any time and
                  Horizon has the right to manage, regulate, control, modify, or
                  remove any or all Gaming Tokens in our sole discretion, in which
                  case, unless prohibited by applicable law, we will have no liability
                  to you or anyone for the exercise of such rights. If any such change
                  is unacceptable to you, your sole recourse is to terminate your
                  account. Unless prohibited by applicable law, which may include the
                  laws of Quebec, Canada, in which case we will effect such changes in
                  accordance with such laws, your continued use of the Websites or
                  Skyweaver after notice of the changes will indicate your acceptance
                  of those changes. The price of Gaming Tokens on secondary markets
                  may vary depending on a variety of factors including where or how
                  you obtain them. The price and availability of Gaming Tokens on
                  secondary markets may change without notice.
               </p>
               <br />
               <SubModified id="walletConversion">
                  2. Wallet and Conversion Contract Terms
               </SubModified>

               <SubModified>2.1 Wallet Terms</SubModified>
               <p>
                  Account Holders will be required to have a Sequence Wallet in order
                  to create an Account. The Sequence Wallet provides Account Holders
                  with access to an interface allowing them to execute instructions
                  directly on the Ethereum blockchain network (the “
                  <b>Sequence</b>
                  <b>Wallet</b>”). The Sequence Wallet allows users to view
                  balances for digital assets that are compatible with the Wallet (“
                  <b>Blockchain Assets</b>”), initiate transfers and
                  transactions involving Blockchain Assets, and as an authenticating
                  account to access Skyweaver and other products and games. Your use
                  of the Sequence Wallet will be governed by the terms located at{' '}
                  <a
                     href="https://sequence.build/terms"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     https://sequence.build/terms
                  </a>
                  {' '}and you must accept those terms before creating a Sequence Wallet
                  and establishing an Account to play Skyweaver and other Games.
               </p>
               <br />
               <SubModified>2.2 Conversion Contract</SubModified>
               <p>
                  The Conversion Contract consists of a series of open source smart
                  contracts published by Horizon that will allow a user to, in a
                  self-directed process, lock one digital asset that conforms with the
                  ERC-20 standard in exchange for the creation and transfer to the
                  user of a digital asset with the same features that conforms with
                  the ERC-1155 standard. All action executed through the Conversion
                  Contract is directed by users and does not involve the intervention
                  of Horizon. Users are responsible for executing the Conversion
                  Contract and for the outcomes of such execution. Users agree and
                  acknowledge that neither Horizon nor any other individual will have
                  control over any digital assets locked in the smart contracts
                  related to the Conversion Contract. Users may send instructions to
                  the Conversion Contract through the user interface provided by the
                  Skyweaver or directly through other means.<b> </b>
               </p>
               <br />
               <SubModified id="noncashGames">
                  3. Non-Cash Games of Skill
               </SubModified>

               <SubModified>3.1 Eligibility</SubModified>
               <p>
                  Horizon may make available to Account Holders who play Skyweaver,
                  two games of skill, neither of which award cash prizes: (a) a weekly
                  leaderboard contest (“<b>Weekly Contest</b>”) and (b)
                  asynchronous tournaments available from time to time (“
                  <b>Conquest Mode</b>”) (together, the “<b>Contests</b>
                  ”).
               </p>
               <br />
               <SubModified>3.2 Weekly Contest</SubModified>
               <p>
                  <b>(a)</b>{' '}
                  <b>Method of Entry.</b> All eligible Account Holders will be
                  entered into the Weekly Contest automatically.
               </p><br />
               <p>
                  <b>(b)</b>{' '}
                  <b>Timing.</b> The Weekly Contest begins at 14:00 UTC on
                  Monday of every week starting Nov 25th 2021.
               </p><br />
               <p>
                  <b>(c)</b>{' '}
                  <b>Winner Determination.</b> At 14:00 UTC on Monday of every
                  week, the Platform will take a snapshot of the rankings of all
                  players depending on the amount of games played and their ELO
                  rating. Top ranked Account Holders in the snapshot will be
                  considered a winner, as determined by Horizon in its sole
                  discretion, and eligible for a prize. The chances of winning will
                  depend on a player's skill level and, if playing constructed mode,
                  available cards.
               </p><br />
               <p>
                  <b>(d)</b>{' '}
                  <b>Prizes. </b>Each winner will be eligible to receive a prize
                  based on their ranking. Prizes will consist solely of non-cash items
                  and will automatically be delivered to the winner by Horizon. Prizes
                  are intended for use primarily in gameplay to enhance the
                  entertainment value of gameplay. Any costs or expenses incurred by
                  the winning players in claiming or using their prizes will be the
                  responsibility of the winners. Prizes must be accepted as awarded;
                  no USDC or cash substitutes. The prizes for each rank will be
                  determined by Horizon, in its sole discretion, on a periodic basis.
                  Horizon reserves the right to discontinue Weekly Contests at any
                  time for any reason.
               </p>
               <br />
               <SubModified>3.3 Conquest Mode</SubModified>
               <p>
                  <b>(a)</b>{' '}
                  <b>Method of Entry. </b>There are two methods of entry into a
                  Conquest Mode tournament: (a) entry by payment of an entry fee, to
                  be determined by Horizon in its sole discretion, in the form of USDC
                  or an equivalent amount of USD to Horizon; or (b) entry by an
                  Account Holder's transfer of one Silver Card to a blockchain address
                  designated by Horizon. Silver Cards used to enter Conquest will be
                  burned, and will not be held or utilized by Horizon.
               </p><br />
               <p>
                  <b>(b)</b>{' '}
                  <b>Timing and Winner Determination.</b>Tournaments in Conquest
                  Mode are asynchronous and an individual tournament entrant begins a
                  match once another player with the same number of victories enters
                  the queue. At any point after a win has occurred between two players
                  (a “<b>Victory</b>”), the winning player will continue play
                  and the defeated player exits with the Rewards they have earned. The
                  Tournament for each individual player ends after three consecutive
                  Victories or when the player was defeated, whichever happens first.
                  The chances of a Victory will depend on a player's available cards
                  and skill level.
               </p><br />
               <p>
                  <b>(c)</b>{' '}
                  <b>Prizes. </b>Players with at least one Victory are eligible
                  to receive a prize. Prizes will consist solely of non-cash items and
                  will automatically be delivered to the player by Horizon. Prizes are
                  intended for use primarily in gameplay to enhance the entertainment
                  value of gameplay. Any costs or expenses incurred by the winning
                  players in claiming or using their prizes will be the responsibility
                  of the winners. Prizes must be accepted as awarded; no USDC or cash
                  substitutes. The prizes for each number of Victories will be
                  determined by Horizon, in its sole discretion, on a periodic basis.
                  Horizon reserves the right to discontinue Conquest Mode tournaments
                  at any time for any reason.
               </p>
               <br />
               <SubModified>3.4 Conditions</SubModified>
               <p>
                  Account Holders shall not: (a) cheat,
                  employ fraud, or attempt to circumvent these Terms; (b) carry out
                  any kind of spamming; (c) collude with or bribe other Account
                  Holders; or (d) otherwise behave in a manner that Horizon deems,
                  unless prohibited by applicable law, in its sole discretion,
                  offensive or inappropriate. Such behavior will result in an Account
                  Holder being permanently banned from all Contests.
               </p>
               <br />
               <SubModified>3.5 Limitations of Liability</SubModified>
               <p>
                  SOME JURISDICTIONS (WHICH MAY INCLUDE QUEBEC, CANADA) DO NOT ALLOW
                  THE EXCLUSION OR LIMITATION OF LIABILITY, INCLUDING LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE FOLLOWING
                  LIMITATION MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
                  Horizon is not responsible for: (a) any incorrect or inaccurate
                  information, whether caused by Account Holders, errors, or by any of
                  the equipment or programming associated with or utilized in the
                  Contests; (b) technical failures of any kind, including, but not
                  limited to malfunctions, interruptions, or disconnections in phone
                  lines or network, service providers, access providers, hardware or
                  software; (c) unauthorized human intervention in any part of the
                  Contests; or (d) technical or human error in the administration of
                  the Contests or the delivery of the prizes. No more than the stated
                  number of each prize will be awarded.
               </p><br />
               <p>
                  Horizon reserves the right to cancel or modify the Contests if, in
                  its sole discretion, it determines that a Contest is not capable of
                  running as originally planned for any reason (such as tampering or
                  infection by computer virus, fraud, technical failures, printing or
                  distribution errors, or any other causes or occurrences have
                  compromised the fairness or integrity of the Contest).
               </p>
               <br />
               <SubModified>3.6 Quebec, Canada</SubModified>
               <p>
                  Any litigation respecting the conduct or organization of a publicity
                  contest may be submitted to the Régie des alcools, des courses et
                  des jeux for a ruling. Any litigation respecting the awarding of a
                  prize may be submitted to the Régie only for the purpose of helping
                  the parties reach a settlement.
               </p>
               <br />
               <SubModified id="refundPolicy">4. Refund Policy</SubModified>
               <SubModified>4.1</SubModified>
               <p>
                  All purchases of Gaming Tokens or Conquest Mode entries are final
                  and Horizon does not issue refunds.
               </p><br />
               <SubModified>4.2</SubModified>
               <p>
                  Purchases of cryptocurrency using one of the third-party payment
                  providers through the Sequence Wallet or using the Apple Store or
                  Google Store are subject to the refund policy of the relevant
                  third-party service provider. Links to those policies are provided
                  below for your reference:
               </p><br />
               <p>
                  <ul>
                     <li>
                        <a
                           href="https://www.apple.com/legal/internet-services/itunes/"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Apple Media Services Terms and Conditions
                        </a>
                     </li>
                     <li>
                        <a
                           href="https://play.google.com/intl/en_fk/about/play-terms/"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Google Play Terms of Service
                        </a>
                     </li>
                     <li>
                        <a
                           href="https://ramp.network/terms-of-service/"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Ramp Payment Provider Terms of Service
                        </a>
                     </li>
                     <li>
                        <a
                           href="https://www.sendwyre.com/user-agreement/"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Wyre Payment Provider User Agreement
                        </a>
                     </li>
                     <li>
                        <a
                           href="https://www.moonpay.com/legal/terms_of_use"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Moonpay Payment Provider Terms of Use (non-USA only)
                        </a>
                     </li>
                     <li>
                        <a
                           href="https://www.moonpay.com/legal/terms_of_use_usa"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           Moonpay Payment Provider Terms of Use (USA only)
                        </a>
                     </li>
                  </ul><br />
               </p>
               <SubModified>4.3</SubModified>
               <p>
                  You are solely responsible for securing your login credentials and
                  any device you use to access your Sequence Wallet. You understand
                  that anyone who obtains your login credentials and access to your
                  device may access your Sequence Wallet with or without your
                  authorization and may transfer any blockchain assets accessible
                  through your Sequence Wallet or engage in the unauthorized purchase
                  of cryptocurrency. Horizon does not have control or have access to
                  your Sequence Wallet and will not provide any refunds in the event
                  of such an unauthorized purchase or transfer of assets.
               </p><br />
               <SubModified>4.4</SubModified>
               <p>
                  For additional information regarding our refund policy, please see
                  our{' '}
                  <a
                     href="https://support.skyweaver.net/en/articles/6105620-general-info-about-skyweaver-s-refund-policy"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     Refunds FAQs
                  </a>
                  ,
               </p>
               <br />
               <SubModified id="websiteTerms">5. Website Terms</SubModified>

               <SubModified>5.1 Access to the Website</SubModified>

               <p>
                  <b>(a)</b>{' '}
                  <b>License. </b> Subject to these Terms, Horizon grants you a
                  non-transferable, non-exclusive, revocable, limited license to use
                  and access the Websites for your own personal and non-commercial
                  use.
               </p><br />
               <p>
                  <b>(b) Certain Restrictions. </b>The rights granted to you in
                  these Terms are subject to the following restrictions: (a) you shall
                  not license, sell, rent, lease, transfer, assign, distribute, host,
                  or otherwise commercially exploit the Websites, whether in whole or
                  in part, or any content displayed on the Websites; (b) you shall not
                  (directly or indirectly) modify, decipher, disassemble, reverse
                  compile or reverse engineer or otherwise attempt to derive any
                  source code or underlying ideas or algorithms of any part of the
                  Services; (c) you shall not access the Websites in order to build a
                  similar or competitive website, product, or service; (d) you shall
                  not translate, or otherwise create derivative works, adaptations,
                  translations or compilations of any part of the Services; (e) you
                  shall not rent, lease, distribute, or otherwise transfer any of the
                  rights that you receive hereunder; (f) you shall not frame or mirror
                  any part of the Websites without Horizon's express prior written
                  consent; (g) you shall not create a database by systematically
                  downloading and storing content related to the Services; (h) you
                  shall not use any robot, spider, site search/retrieval application
                  or other manual or automatic device to retrieve, harvest, index,
                  “scrape,” “data mine” or in any way gather content related to the
                  Services or reproduce or circumvent the navigational structure or
                  presentation of the Services without Horizon's express prior written
                  consent; and (i) except as expressly stated herein, no part of the
                  Websites may be copied, reproduced, distributed, republished,
                  downloaded, displayed, posted or transmitted in any form or by any
                  means. Unless otherwise indicated, any future release, update, or
                  other addition to functionality of the Websites shall be subject to
                  these Terms. All copyright and other proprietary notices on the
                  Websites (or on any content displayed on the Websites) must be
                  retained on all copies thereof.
               </p><br />
               <p>
                  <b>(c)</b>{' '}
                  <b>Modification of the Websites.</b> Horizon reserves the
                  right, at any time, to modify, suspend, or discontinue the Websites
                  (in whole or in part) with or without notice to you. You agree that
                  Horizon will not be liable to you or to any third party for any
                  modification, suspension, or discontinuation of the Websites or any
                  part thereof.
               </p><br />
               <p>
                  <b>(d)</b>{' '}
                  <b>No Support or Maintenance.</b> You acknowledge and agree
                  that Horizon will have no obligation to provide you with any support
                  or maintenance in connection with the Websites, unless specifically
                  contracted for under a separate agreement.
               </p><br />
               <p>
                  <b>(e)</b>{' '}
                  <b>Ownership. </b>Excluding any User Content that you may
                  provide (defined below), you acknowledge that all the intellectual
                  property rights, including copyrights, patents, trademarks, and
                  trade secrets, in the Websites and their content are owned by
                  Horizon. Neither these Terms nor your access to the Websites
                  transfers to you or any third party any rights, title or interest in
                  or to such intellectual property rights, except for the limited
                  access rights expressly set forth in these Terms. Horizon reserves
                  all rights not granted in these Terms. There are no implied licenses
                  granted under these Terms.
               </p><br />

               <SubModified>5.2 Accuracy of Information</SubModified>
               <p>
                  We attempt to ensure that the information that we provide on the
                  Websites is complete, accurate and current. Despite our efforts, the
                  information on Websites may occasionally be inaccurate, incomplete
                  or out of date. We make no representation as to the completeness,
                  accuracy or correctness of any information on any Website.
               </p><br />

               <SubModified>5.3 Links to Third Party Website</SubModified>
               <p>
                  The Websites, Platform, and Services may now or in the future
                  contain links to third party websites. Where provided, these links
                  are provided as a convenience to you. Horizon does not control and
                  is not responsible for the content of such third-party websites or
                  the conduct of the operators of such third-party websites, and we do
                  not make any representations regarding the accuracy, copyright, or
                  other statutory or regulatory compliance, legality, or decency of
                  any of the content or other materials on such third-party websites.
                  If you decide to access linked third-party websites, you do so at
                  your own risk.
               </p><br />

               <SubModified id="disclaimers">6. Disclaimers</SubModified>

               <p>
                  SOME JURISDICTIONS (WHICH MAY INCLUDE QUEBEC) DO NOT ALLOW THE
                  EXCLUSION OR LIMITATION OF LIABILITY, INCLUDING LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE FOLLOWING
                  LIMITATION MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
               </p><br />
               <p>
                  THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS,
                  AND HORIZON (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL
                  WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR
                  STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF
                  MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR
                  PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE
                  MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, WILL
                  BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
                  BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER
                  HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES
                  ANY WARRANTIES WITH RESPECT TO THE SERVICES, ALL SUCH WARRANTIES ARE
                  LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.
               </p><br />
               <p>
                  HORIZON DOES NOT ENDORSE ANY OTHER THIRD PARTY AND SHALL NOT BE
                  RESPONSIBLE IN ANY WAY FOR ANY TRANSACTIONS YOU ENTER INTO WITH
                  OTHER USERS. YOU AGREE THAT HORIZON WILL NOT BE LIABLE FOR ANY LOSS
                  OR DAMAGES OF ANY SORT INCURRED AS THE RESULT OF ANY INTERACTIONS
                  BETWEEN YOU AND OTHER USERS.
               </p><br />

               <SubModified id="termination">
                  7. Terms and Termination
               </SubModified>

               <p>
                  These Terms will remain in full force and effect while you use the
                  Services. We may suspend or terminate your rights to use the
                  Services at any time for any reason at our sole discretion,
                  including for any use of the Services in violation of these Terms.
                  Upon termination of your rights under these Terms, your right to
                  access and use the Services will terminate immediately. You
                  understand that any termination of your rights may involve removal
                  of your User Content from our live databases. Horizon will not have
                  any liability whatsoever to you for any termination of your rights
                  under these Terms, including for deleting your account or
                  blacklisting any network address you provide to us. Even after your
                  rights under these Terms are terminated, the following provisions of
                  these Terms will remain in effect: Section 1.1, Section 5.1, Section
                  6, Section 8, Section 9, Section 11 and Section 12.
               </p><br />

               <SubModified id="otherUsers">8. Other Users</SubModified>

               <p>
                  Each user is solely responsible for any and all of its own User
                  Content. Because we do not control User Content and/or other
                  third-party sites and resources, you acknowledge and agree that we
                  are not responsible for the accuracy or availability of any User
                  Content and materials and/or external sites or resources, whether
                  provided by you or by others. We make no guarantees regarding the
                  accuracy, currency, suitability, or quality of any User Content.
                  Your interactions with other users are solely between you and such
                  users. You agree that Horizon will not be responsible for any loss
                  or damage incurred as the result of any such interactions. If there
                  is a dispute between you and any user, we are under no obligation to
                  become involved.
               </p><br />

               <SubModified>8.1 Release</SubModified>
               <p>
                  SOME JURISDICTIONS (WHICH MAY INCLUDE QUEBEC, CANADA) DO NOT ALLOW
                  THE EXCLUSION OR LIMITATION OF LIABILITY, SO THE FOLLOWING
                  LIMITATION MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
                  You hereby release and forever discharge Horizon (and our officers,
                  employees, agents, successors, and assigns) from, and hereby waive
                  and relinquish, each and every past, present and future dispute,
                  claim, controversy, demand, right, obligation, liability, action and
                  cause of action of every kind and nature (including personal
                  injuries, death, and property damage), that has arisen or arises
                  directly or indirectly out of, or that relates directly or
                  indirectly to, the Services (including any interactions with, or act
                  or omission of, other users or any Third-Party Links & Ads). IF YOU
                  ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
                  SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A
                  GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
                  NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                  EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
                  MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
               </p><br />

               <SubModified id="userContent">9. User Content</SubModified>

               <SubModified>9.1 User Content</SubModified>
               <p>
                  “<b>User Content</b>” means any and all information and
                  content that a user submits to, or uses with, the Services (e.g.,
                  content in the avatar, username, private or public chats or forums),
                  including Creator Content (defined below).
                  You are solely responsible for your User Content. You assume all
                  risks associated with use of your User Content, including any
                  reliance on its accuracy, completeness or usefulness by others, or
                  any disclosure of your User Content that personally identifies you
                  or any third party. You hereby represent and warrant that your User
                  Content does not violate our Acceptable Use Policy (defined in
                  Section 9.2). You may not represent or imply to others that your
                  User Content is in any way provided, sponsored or endorsed by
                  Horizon. Because you alone are responsible for your User Content,
                  you may expose yourself to liability if, for example, your User
                  Content violates the Acceptable Use Policy. Horizon is not obligated
                  to backup any User Content, and your User Content may be deleted at
                  any time without prior notice. You are solely responsible for
                  creating and maintaining your own backup copies of your User Content
                  if you desire.
               </p><br />

               <SubModified>9.2 Acceptable Use Policy</SubModified>
               <p>
                  The following terms constitute our “
                  <b>Acceptable Use Policy</b>”:
               </p><br />
               <p>
                  <b>(a)</b> You agree not to use the Services to collect,
                  upload, transmit, display, or distribute any User Content: (i) that
                  violates any third-party right, including any copyright, trademark,
                  patent, trade secret, moral right, privacy right, right of
                  publicity, or any other intellectual property or proprietary right;
                  (ii) that is unlawful, harassing, abusive, tortious, threatening,
                  harmful, invasive of another's privacy, vulgar, defamatory, false,
                  intentionally misleading, trade libelous, pornographic, obscene,
                  patently offensive, promotes racism, bigotry, hatred, or physical
                  harm of any kind against any group or individual or is otherwise
                  objectionable; (iii) that is harmful to minors in any way; or (iv)
                  that is in violation of any law, regulation, or obligations or
                  restrictions imposed by any third party.
               </p><br />
               <p>
                  <b>(b)</b> In addition, you agree not to: (i) upload,
                  transmit, or distribute to or through the Services any computer
                  viruses, worms, or any software intended to damage or alter a
                  computer system or data; (ii) send through the Services unsolicited
                  or unauthorized advertising, promotional materials, junk mail, spam,
                  chain letters, pyramid schemes, or any other form of duplicative or
                  unsolicited messages, whether commercial or otherwise; (iii) use the
                  Services to harvest, collect, gather or assemble information or data
                  regarding other users, including e-mail addresses, without their
                  consent; (iv) interfere with, disrupt, or create an undue burden on
                  servers or networks connected to the Services, or violate the
                  regulations, policies or procedures of such networks; (v) attempt to
                  gain unauthorized access to the Services (or to other computer
                  systems or networks connected to or used together with the
                  Services), whether through password mining or any other means; (vi)
                  harass or interfere with any other user's use and enjoyment of the
                  Services; or (vii) use software or automated agents or scripts to
                  produce multiple accounts on the Services, or to generate automated
                  searches, requests, or queries to (or to strip, scrape, or mine data
                  from) the Services (provided, however, that we may conditionally
                  grant to the operators of public search engines revocable permission
                  to use spiders to copy materials from the Services for the sole
                  purpose of and solely to the extent necessary for creating publicly
                  available searchable indices of the materials, but not caches or
                  archives of such materials, subject to the parameters set forth in
                  our robots.txt file).
               </p><br />

               <SubModified>9.3 Enforcement</SubModified>
               <p>
                  We reserve the right (but have no obligation) to review any User
                  Content, and to investigate and/or take appropriate action against
                  you in our sole discretion if you violate the Acceptable Use Policy
                  or any other provision of these Terms or otherwise create liability
                  for us or any other person. Such action may include deleting your
                  Account, blacklisting your Wallet address, and/or reporting you to
                  law enforcement authorities.
               </p><br />

               <SubModified>9.4 Feedback</SubModified>
               <p>
                  If you provide Horizon with any feedback or suggestions regarding
                  the Services (“<b>Feedback</b>”), you hereby assign to Horizon
                  all rights in such Feedback and agree that Horizon shall have the
                  right to use and fully exploit such Feedback and related information
                  in any manner it deems appropriate. Horizon will treat any Feedback
                  you provide to Horizon as non-confidential and non-proprietary. You
                  agree that you will not submit to Horizon any information or ideas
                  that you consider to be confidential or proprietary.
               </p>

               <SubModified>9.5 Creators Program</SubModified>
               <p>
                  <b>(a)</b>{' '}Horizon offers an optional creators program (the “Creators Program”) whereby certain
                  users approved by us (“Approved Creators”) will be provided with access to unique rewards and tools,
                  as determined and made available by us in our sole discretion from time to time, to develop and
                  publish Creator Content through the Services for use by the Approved Creator, Horizon,
                  and other users of the Services. “Creator Content” includes any content, data, information,
                  records or files, that you load, transmit to us or enter into the Services, or that you
                  otherwise submit to us in connection with your participation in the Creators Program.
                  All Creator Content is deemed to be included in the definition of User Content, as applicable.
                  Users may apply to the Creators Program through our sign-up page available at{' '}
                  <a
                     href="https://www.skyweaver.net/community/creators-program/"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     https://www.skyweaver.net/community/creators-program/
                  </a>{' '}
                  and we reserve the right to approve or reject users for participation in the Creators Program in our sole discretion.
                  Additionally, if we have approved any user to be an Approved Creator, we reserve the right to revoke that approval
                  at any time in our sole discretion.
               </p>
               <br />
               <p>
                  <b>(b)</b>{' '}Approved Creators hereby grant to us and our affiliates and service providers,
                  and each of their and our respective licensees, successors and assigns, a
                  fully paid up, perpetual,  irrevocable, worldwide, royalty-free,  non-exclusive,
                  transferable and fully and sublicensable licence to access, collect, store and use any data, information,
                  records or files that you, as an Approved Creator, load, transmit to, enter into, or that we collect from,
                  the Services, including your personal information (collectively, “Creator Data”) to, without payment of any
                  compensation or obligations to you: (i) develop, enhance and make available the Services; and (ii) produce data, information,
                  or other materials that are not identified as relating to a particular individual or company (such data,
                  information and materials, the “Aggregated Data”). We and our affiliates and service providers, and each of
                  their and our respective licensees, successors and assigns are free to create, use and disclose Aggregated
                  Data during and after your use of the Services for any purpose, expressly including commercial purposes,
                  and without any compensation or obligations to you. Unless prohibited by applicable law, which may include
                  the laws of Quebec, Canada, Horizon will not be liable for any failure to store, or for loss or corruption of, your Creator Data.
               </p>
               <br />
               <p>
                  <b>(c)</b>{' '}By participating in the Creators Program as an Approved Creator, you acknowledge and agree that Horizon will be transmitting and Handling your personal information electronically. Please review our current Privacy Policy, which contains important information about our practices in Handling personal information in connection with the Creators Program. The term “Handle” means to access, receive, collect, use, transmit, store, process, record, disclose, transfer, retain, dispose of, destroy, manage or otherwise handle.
               </p>
               <br />
               <p>
                  <b>(d)</b>{' '}When Approved Creators post, publish or otherwise make publicly available any Creator Content, Approved Creators grant (i) us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns, and (ii) each user of the Services,      a fully paid up, perpetual, irrevocable, worldwide, royalty-free, non-exclusive, transferable and fully sublicensable licence to access, use, license, distribute, reproduce, edit, modify, adapt, publicly perform, publicly display, prepare derivative works from, derive revenue or other remuneration from, and otherwise disclose to third parties, the Creator Content (in whole or in part) for any purpose, including commercial purposes, and/or to incorporate it in other works in any form, media or technology now known or later developed, throughout the world without compensation to you, for the full term of any worldwide intellectual property right that may exist in the Creator Content, including after your cessation of your participation in the Creators Program and/or your termination of your account or the Services. Approved Creators waive any moral rights, author’s rights or other rights of authorship upon posting, publishing otherwise making publicly available any Creator Content.
               </p>
               <br />
               <p>
                  <b>(e)</b>{' '}We reserve the right to remove, edit, limit, or block access to the Creator Data or the Creator Content at any time and from time to time, and to disable or terminate the Creators Program, in whole or in part, or your status as an Approved Creator in our sole discretion for any or no reason, including for any violation of any provision of these Terms and our Acceptable Use Policy. We have no obligation to display, publish, review or use your Creator Content.
               </p>
               <br />
               <p>
                  <b>(f)</b>{' '}You, as an Approved Creator, understand and agree that you, and not Horizon, are fully responsible for all of the Creator Content, and you are fully responsible and legally liable, including to any third party, for such content and its accuracy. Horizon is not responsible or legally liable to any third party for the content or accuracy of any of the Creator Content or such content uploaded, posted, e-mailed, transmitted or otherwise made publicly available through the Services by any Approved Creators.
               </p>
               <br />
               <p>
                  <b>(g)</b>{' '}You, as an Approved Creator, represent and warrant to us that: (i) the Creator Data and the Creator Content will only contain your own personal information or personal information in respect of which you have provided all necessary notices and disclosures, obtained all applicable third party consents and permissions and otherwise have all authority, in each case as required by applicable laws, to enable us to make available the Services and Handle the Creator Data as contemplated under these Term;  (ii) you own or control the appropriate rights in and to the Creator Data and the Creator Content, including any intellectual property owned by third parties and including the right to grant the licenses to the Creator Content contained herein; and (iii) you will not submit, upload, or otherwise make available via the Services, any Creator Data or Creator Content that: (A) you do not have the rights necessary to use, post, publish or otherwise make publicly available, or to grant us the licenses as described herein; (B) infringe, misappropriate, or otherwise violate any rights of any third party, including intellectual property or publicity rights; or (C) breach or violate any applicable laws or these Terms.
               </p>
               <br />
               <p>
                  <b>(h)</b>{' '}Approved Creators acknowledge and agree that they will not receive monetary remuneration or compensation from Horizon or any other party for participating in the Creators Program. Approved Creators acknowledge and agree that they are not an employee or independent contractor of Horizon for any purpose, including, but not limited to, applicable employment or labour standards legislation or workers compensation legislation, any of the regulations made pursuant thereto, or otherwise at law or in equity. Approved Creators are not and will not represent themselves to be an agent, joint venturer, partner, officer, director or employee of Horizon. Approved Creators agree not to make any representations or engage in any acts that could establish an apparent relationship of agency, joint venture, partnership or employment with Horizon. Nothing contained in these Terms creates nor will be construed as creating an employment relationship between Approved Creators and Horizon.
               </p>
               <br />

               <SubModified id="disclaimers">
                  10. Disclaimers Regarding Gaming Tokens
               </SubModified>

               <SubModified>10.1</SubModified>
               <p>
                  You expressly acknowledge and agree that your participation in any
                  Skyweaver game, and your receipt and possession of any Gaming Token,
                  is voluntary and for entertainment purposes only.
               </p><br />

               <SubModified>10.2</SubModified>
               <p>
                  You expressly acknowledge that Horizon is under no obligation, and
                  you are in no way relying on Horizon, to promote, support, or
                  improve Skyweaver, and that Horizon reserves the right to cease
                  support for Skyweaver and to terminate the availability of Skyweaver
                  at any time, and that Horizon may cease to sell or award new Gaming
                  Tokens on Skyweaver.
               </p><br />

               <SubModified>10.3</SubModified>
               <p>
                  You expressly acknowledge that your receipt, purchase, or possession
                  of Gaming Tokens does not give you any rights in or claims against
                  Horizon, that Gaming Tokens are not a financial or investment
                  instrument of any kind, that Gaming Tokens may not have any
                  real-world monetary value whatsoever, and that Horizon is not
                  responsible for any value attributable to, or fluctuations or loss
                  in the value of, Gaming Tokens.
               </p><br />

               <SubModified>10.4</SubModified>
               <p>
                  While Horizon has taken a number of precautions to ensure the
                  security of the Gaming Tokens, the Wallet and Conversion Contract,
                  the technology is relatively new and it is not possible to guarantee
                  that the code is 100% free from bugs or errors. Users accept all
                  risks that arise from using the Wallet, the Gaming Tokens and the
                  Conversion Contract, including but not limited to the risk of any
                  funds being lost due to a failure of the Conversion Contract. Your
                  Gaming Tokens may be lost, stolen, or otherwise rendered unusable
                  due to bugs in smart contracts implementing the Game, the Wallet,
                  the Conversion Contract, other wallets and the Ethereum network.
               </p><br />

               <SubModified id="indemnification">11.Indemnification</SubModified>

               <p>
                  You agree to indemnify and hold Horizon (and its officers,
                  employees, and agents) harmless, including costs and attorneys'
                  fees, from any claim or demand made by any third party due to or
                  arising out of: (i) your use of the Services; (ii) your violation of
                  these Terms; (iii) your violation of applicable laws or regulations;
                  or (iv) your User Content, expressly including, if applicable, Creator Content.
                  Horizon reserves the right, at your
                  expense, to assume the exclusive defense and control of any matter
                  for which you are required to indemnify us, and you agree to
                  cooperate with our defense of these claims. You agree not to settle
                  any matter without the prior written consent of Horizon. Horizon
                  will use reasonable efforts to notify you of any such claim, action
                  or proceeding upon becoming aware of it.
               </p><br />

               <SubModified id="liability">
                  12. Limitation on Liability
               </SubModified>

               <p>
                  SOME JURISDICTIONS (WHICH MAY INCLUDE QUEBEC, CANADA) DO NOT ALLOW
                  THE EXCLUSION OR LIMITATION OF LIABILITY, INCLUDING LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE FOLLOWING
                  LIMITATION MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
               </p><br />
               <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL HORIZON BE
                  LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, OR
                  ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
                  PUNITIVE DAMAGES ARISING OUT OF YOUR USE OF THE SERVICES, EVEN IF
                  HORIZON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS
                  TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND
                  YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR
                  COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
               </p><br />
               <p>
                  HORIZON SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE ARISING OUT OF
                  YOUR FAILURE TO KEEP YOUR PRIVATE KEYS OR LOGIN CREDENTIALS TO YOUR
                  WALLET SECURE OR ANY OTHER UNAUTHORIZED ACCESS TO OR TRANSACTIONS
                  INVOLVING YOUR WALLET.
               </p><br />
               <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO
                  THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES
                  ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER
                  AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE
                  LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE
                  OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
               </p><br />

               <SubModified id="disputeResolution">
                  13. Dispute Resolution
               </SubModified>

               <p>
                  Please read this section carefully. It is part of your contract with
                  Horizon and affects your rights. You and Horizon are each considered
                  “<b>Parties</b>” for the purpose of this section. SOME
                  JURISDICTIONS (WHICH MAY INCLUDE QUEBEC, CANADA) DO NOT ALLOW
                  MANDATORY ARBITRATION, PROHIBITIONS AGAINST CLASS ACTIONS OR
                  GOVERNING LAW AND FORUMS OTHER THAN WHERE THE CONSUMER IS LOCATED.
                  IF YOU ARE LOCATED IN ONE OF THESE JURISDICTIONS, THE FOLLOWING MAY
                  NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
               </p><br />

               <SubModified>13.1 Mediation</SubModified>
               <p>
                  If any dispute occurs between the Parties relating to the
                  application, interpretation, implementation or validity of these
                  Terms, the Parties agree to seek to resolve the dispute or
                  controversy through mediation with Canadian Arbitration Association
                  before pursuing any other proceedings. Nothing herein shall preclude
                  any Party from seeking injunctive relief in the event that the Party
                  perceives that without such injunctive relief, serious harm may be
                  done to the Party. Any Party to the dispute may serve notice on the
                  others of its desire to resolve a particular dispute by mediation.
                  The mediator shall be appointed by agreement between the Parties or,
                  if the Parties cannot agree within five days after receipt of the
                  notice of intention to mediate, the mediator will be appointed by
                  Canadian Arbitration Association. The mediation will be held at
                  Toronto, Ontario, Canada. The Parties agree to attempt to resolve
                  their dispute at mediation. The costs of the mediator shall be
                  shared equally by the Parties. If the dispute has not been resolved
                  within thirty days of the notice of desire to mediate, any Party may
                  terminate the mediation and proceed to arbitration as set out below.
               </p><br />

               <SubModified>13.2 Arbitration</SubModified>
               <p>
                  Subject to the mediation provisions set out above, if any dispute or
                  controversy occurs between the Parties relating to the
                  interpretation or implementation of any of the provisions of this
                  Agreement, the dispute will be resolved by arbitration at Canadian
                  Arbitration Association pursuant to the general Canadian Arbitration
                  Association Rules for Arbitration. Any Party may serve notice of its
                  desire to refer a dispute to arbitration. The arbitration shall be
                  conducted by a single arbitrator. The arbitration shall be held in
                  Toronto, Ontario, Canada. The arbitration shall proceed in
                  accordance with the provisions of the Arbitration Act (Ontario). The
                  decision arrived at by the arbitrator shall be final and binding and
                  no appeal shall lie therefrom. Judgement upon the award rendered by
                  the arbitrator may be entered in any court having jurisdiction. The
                  costs of the arbitrator shall be divided equally between the
                  Parties.
               </p><br />

               <SubModified id="disclosures">14. Disclosures</SubModified>

               <p>
                  Horizon is located in Ontario, Canada. If you are a California
                  resident, you may report complaints to the Complaint Assistance Unit
                  of the Division of Consumer Product of the California Department of
                  Consumer Affairs by contacting them in writing at 400 R Street,
                  Sacramento, CA 95814, or by telephone at (800) 952-5210.
               </p><br />

               <SubModified id="electronicCommunications">
                  15. Electronic Communications
               </SubModified>

               <p>
                  The communications between you and Horizon use electronic means,
                  whether you use the Services or send us emails, or whether Horizon
                  posts notices on the Services or communicates with you via email.
                  For contractual purposes, you: (i) consent to receive communications
                  from Horizon in an electronic form; and (ii) agree that all terms
                  and conditions, agreements, notices, disclosures, and other
                  communications that Horizon provides to you electronically satisfy
                  any legal requirement that such communications would satisfy if it
                  were be in a hardcopy writing. The foregoing does not affect your
                  non-waivable rights.
               </p><br />

               <SubModified id="jurisdiction">
                  16. Governing Law and Jurisdiction
               </SubModified>

               <p>
                  SOME JURISDICTIONS HAVE CONSUMER PROTECTION AND OTHER LEGISLATION
                  WHICH MAY APPLY TO THE SERVICES AND WHICH DO NOT ALLOW CERTAIN
                  PROVISIONS SUCH AS RESTRICTIONS ON GOVERNING LAW OR JURISDICTION,
                  LIMITATIONS OF LIABILITY AND EXCLUSION OF CERTAIN WARRANTIES, AMONG
                  OTHERS. TO THE EXTENT THAT A RESTRICTION ON GOVERNING LAW OR
                  JURISDICTION, LIMITATION, EXCLUSION, RESTRICTION OR OTHER PROVISION
                  SET OUT BELOW IS SPECIFICALLY PROHIBITED BY APPLICABLE LAW, SUCH
                  LIMITATION, EXCLUSION, RESTRICTION OR PROVISION MAY NOT APPLY TO
                  YOU.
               </p><br />
               <p>
                  These Terms and any dispute or claim arising out of or in connection
                  with their subject matter or formation (including non-contractual
                  disputes or claims) shall be governed by and construed in accordance
                  with the law of Ontario Canada. You agree that the courts of Ontario
                  Canada shall have exclusive jurisdiction to settle any dispute or
                  claim arising out of or in connection with the subject matter or
                  formation (including non-contractual disputes or claims) of these
                  Terms.
               </p><br />
               <p>
                  If you are located outside of the United States or Canada, you use
                  or access the Services solely at your own risk. The Services may not
                  be appropriate or available for use in some jurisdictions. Horizon
                  and its partners do not represent or warrant that the Services or
                  any part thereof is appropriate or available for use in any
                  particular jurisdiction other than the United States or Canada. In
                  choosing to access the Services, you do so on your own initiative
                  and at your own risk, and you are responsible for complying with all
                  local laws, rules and regulations.
               </p><br />

               <SubModified id="general">17. General</SubModified>

               <SubModified>17.1 Entire Terms</SubModified>
               <p>
                  These Terms constitute the entire agreement between you and us
                  regarding the use of the Services. The section titles in these Terms
                  are for convenience only and have no legal or contractual effect.
                  The word “including” means “including without limitation.” If any
                  provision of these Terms is, for any reason, held to be invalid or
                  unenforceable, the other provisions of these Terms will be
                  unimpaired and the invalid or unenforceable provision will be deemed
                  modified so that it is valid and enforceable to the maximum extent
                  permitted by law. You confirm that you are acting on your own behalf
                  and not for the benefit of any other person. Your relationship to
                  Horizon is that of an independent contractor, and neither party is
                  an agent or partner of the other. These Terms, and your rights and
                  obligations herein, may not be assigned, subcontracted, delegated,
                  or otherwise transferred by you without Horizon's prior written
                  consent, and any attempted assignment, subcontract, delegation, or
                  transfer in violation of the foregoing will be null and void.
                  Horizon may freely assign these Terms. The terms and conditions set
                  forth in these Terms shall be binding upon assignees.
               </p><br />

               <SubModified>17.2 Changes</SubModified>
               <p>
                  Unless otherwise required by applicable laws, which may include the
                  laws of Quebec, Canada, if applicable (in which case, we will make
                  changes in accordance with such laws), these Terms are subject to
                  occasional revision. If we make any substantial changes, you will be
                  prompted to review those changes and click to accept the updated
                  Terms when you next log in to your Account. These changes will be
                  effective upon your acceptance of the updated Terms. In addition,
                  continued use of our Services following notice of such changes shall
                  indicate your acknowledgement of such changes and agreement to be
                  bound by the terms and conditions of such changes.
               </p><br />

               <SubModified>17.3 Waiver</SubModified>
               <p>
                  A waiver by Horizon of any right or remedy under these Terms shall
                  only be effective if it is in writing, executed by a duly authorized
                  representative of Horizon and shall apply only to the circumstances
                  for which it is given. Our failure to exercise or enforce any right
                  or remedy under these Terms shall not operate as a waiver of such
                  right or remedy, nor shall it prevent any future exercise or
                  enforcement of such right or remedy. No single or partial exercise
                  of any right or remedy shall preclude or restrict the further
                  exercise of any such right or remedy or other rights or remedies.
               </p><br />

               <SubModified>
                  17.4 Copyright and Trademark Information
               </SubModified>
               <p>
                  Copyright © {new Date().getFullYear()} Horizon Blockchain Games Inc. All rights reserved.
                  All trademarks, logos and service marks (“<b>Marks</b>”)
                  displayed on the Services are our property or the property of other
                  third parties. You are not permitted to use these Marks without our
                  prior written consent or the consent of such third party which may
                  own the Marks.
               </p><br />

               <SubModified>17.5 Contact Information</SubModified>
               <p>
                  You may contact us at any time regarding these Terms at
                  <a href="mailto:hello@horizongames.net"> hello@horizongames.net</a>.
               </p>
            </Body>
         </Section>
      )
   }
}
