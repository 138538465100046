import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Terms from './Terms'
import Image from '../Image'
import Footer from '../Footer'

const Section = styled.section`
  position: relative;
  min-height: 60rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #00001a;

  @media (max-width: 750px) {
    align-items: unset;

    #mobile-fan img {
      opacity: 0;
      display: none;
    }
    #footer-background {
      &::after {
        content: ' ';
        position: absolute;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        background-color: red;
        z-index: 20;
      }
    }
  }
`

const Title = styled.div`
  max-width: 30rem;
  width: 100%;
  padding: 4rem;
  padding-bottom: 0;
  top: 0; left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  margin-left: 50%;
`

const Div = styled.div`
  background-color: #00001a;
`

export default class TermsOfService extends Component {
  state = {
    windowWidth: false
  }

  componentDidMount() {
    this.attachEventListeners()
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  attachEventListeners() {
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = e => {
    this.setState({ windowWidth: window.innerWidth })
  }

  render () {
    const { windowWidth } = this.state
    return (
      <Div>
      <Title>
        <Link to='/'>
          <Image src='/images/skyweaver-logo.svg' alt='Skyweaver Logo' />
        </Link>
      </Title>
      <Section id='tos'>


        <Terms/>

        <Footer windowWidth={windowWidth} mobile={true} fan={false} />

      </Section>
      </Div>
    )
  }
}
