import React, { Component } from 'react'

import '../css/main.css'
import '../css/fonts.css'

import Seo from '../components/SeoHead'
import TermsOfService from '../components/TermsOfService/'

export default class ToS extends Component {
  render() {
    return (
      <>
        <Seo />
        <TermsOfService />
      </>
    )
  }
}
